export const singerStyle = [
  {
    name: "全部歌手",
    type: "-1",
  },
  {
    name: "男歌手",
    type: "1",
  },
  {
    name: "女歌手",
    type: "0",
  },
  {
    name: "组合歌手",
    type: "2",
  },
];
