export const enum Icon {
  BOFANG = "#icon-bofang", // 播放
  ZANTING = "#icon-zanting", // 暂停
  YINLIANG = "#icon-yinliang", // 音量有背景
  YINLIANG1 = "#icon-yinliang1", // 音量无背景
  ERJI = "#icon-erji", // 耳机
  SOUSUO = "#icon-sousuo", // 搜索
  XIAZAI = "#icon-xiazai", // 下载
  ZHEDIE = "#icon-jiantou-xia-cuxiantiao", // 折叠
  SHANGYISHOU = "#icon-ziyuanldpi", // 上一首
  XIAYISHOU = "#icon-ziyuanldpi1", // 下一首
  JINGYIN = "#icon-yinliangjingyinheix", // 静音
  LIEBIAO = "#icon-liebiao", // 列表
  Dislike = "#icon-xihuan1", // 未收藏
  Like = "#icon-xihuan", // 收藏
  Support = "#icon-zan", // 赞
  XUNHUAN = "#icon-xunhuan", // 循环
  LUANXU = "#icon-xunhuan1", // 乱序
}
